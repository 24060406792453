import Dynamic from '@base/Dynamic/Dynamic';
import { abVariations } from '@utils/abHelper';
import {
  getPlusSubscriptionsUids,
  getActiveOfflineSubscriptionUIDs,
  getLiteSubscriptionsUids
} from '@models/Me';
import { readCookie } from '@utils/cookies';
import { fetchGoalInfo } from '@api/fetchers/goal';
import { fetchAccessControl, fetchMyInfo } from '@api/fetchers/user';
import camelcaseKeys from 'camelcase-keys';
import getRedirectionURL from '@cont/Login/utils/getRedirectionURL';
import { Batch } from 'aquilla/icons/featureCards/Batch';
import { Course } from 'aquilla/icons/featureCards/Course';
import { DoubtsAndSolutions } from 'aquilla/icons/featureCards/DoubtsAndSolutions';
import { Educators } from 'aquilla/icons/featureCards/Educators';
import { LiveClasses } from 'aquilla/icons/featureCards/LiveClasses';
import { PlayList } from 'aquilla/icons/featureCards/PlayList';
import { Practice } from 'aquilla/icons/featureCards/Practice';
import { TestSeries } from 'aquilla/icons/featureCards/TestSeries';
import deviceHelper from '@utils/deviceHelper';
import { MwebPractice } from 'aquilla/icons/featureCards/MwebPractice';
import { MwebTest } from 'aquilla/icons/featureCards/MwebTest';
import getCoreSubscriptionUIDs from '@utils/subscriptionHelpers/getCoreSubscriptionUIDs';
import { QR_ATTENDANCE_ROUTE } from '@comp/Attendance/constants';

// eslint-disable-next-line import/prefer-default-export
export const getIcon = (char) => {
  let Component = null;
  switch (char.toUpperCase()) {
    case 'A':
      Component = Dynamic(() => import('aquilla/icons/topics/A'));
      break;
    case 'B':
      Component = Dynamic(() => import('aquilla/icons/topics/B'));
      break;
    case 'C':
      Component = Dynamic(() => import('aquilla/icons/topics/C'));
      break;
    case 'D':
      Component = Dynamic(() => import('aquilla/icons/topics/D'));
      break;
    case 'E':
      Component = Dynamic(() => import('aquilla/icons/topics/E'));
      break;
    case 'F':
      Component = Dynamic(() => import('aquilla/icons/topics/F'));
      break;
    case 'G':
      Component = Dynamic(() => import('aquilla/icons/topics/G'));
      break;
    case 'H':
      Component = Dynamic(() => import('aquilla/icons/topics/H'));
      break;
    case 'I':
      Component = Dynamic(() => import('aquilla/icons/topics/I'));
      break;
    case 'J':
      Component = Dynamic(() => import('aquilla/icons/topics/J'));
      break;
    case 'K':
      Component = Dynamic(() => import('aquilla/icons/topics/K'));
      break;
    case 'L':
      Component = Dynamic(() => import('aquilla/icons/topics/L'));
      break;
    case 'M':
      Component = Dynamic(() => import('aquilla/icons/topics/M'));
      break;
    case 'N':
      Component = Dynamic(() => import('aquilla/icons/topics/N'));
      break;
    case 'O':
      Component = Dynamic(() => import('aquilla/icons/topics/O'));
      break;
    case 'P':
      Component = Dynamic(() => import('aquilla/icons/topics/P'));
      break;
    case 'Q':
      Component = Dynamic(() => import('aquilla/icons/topics/Q'));
      break;
    case 'R':
      Component = Dynamic(() => import('aquilla/icons/topics/R'));
      break;
    case 'S':
      Component = Dynamic(() => import('aquilla/icons/topics/S'));
      break;
    case 'T':
      Component = Dynamic(() => import('aquilla/icons/topics/T'));
      break;
    case 'U':
      Component = Dynamic(() => import('aquilla/icons/topics/U'));
      break;
    case 'V':
      Component = Dynamic(() => import('aquilla/icons/topics/V'));
      break;
    case 'W':
      Component = Dynamic(() => import('aquilla/icons/topics/W'));
      break;
    case 'X':
      Component = Dynamic(() => import('aquilla/icons/topics/X'));
      break;
    case 'Y':
      Component = Dynamic(() => import('aquilla/icons/topics/Y'));
      break;
    case 'Z':
      Component = Dynamic(() => import('aquilla/icons/topics/Z'));
      break;

    default:
      Component = Dynamic(() => import('aquilla/icons/topics/A'));
      break;
  }
  return <Component height={24} width={24} />;
};

export const getABExperimentBucketing = (experimentVariation) => {
  let showUpcomingClass = false;
  let showEducatorFollow = false;
  // test1 - only notify me
  // test2 - only follow educator CTA
  // test3 - both notify me and follow educator CTA
  // and control in it
  if (experimentVariation === abVariations.TEST_1) {
    showUpcomingClass = true;
  } else if (experimentVariation === abVariations.TEST_2) {
    showEducatorFollow = true;
  } else if (experimentVariation === abVariations.TEST_3) {
    showEducatorFollow = true;
    showUpcomingClass = true;
  }
  return { showUpcomingClass, showEducatorFollow };
};

const getRedirectionUrlObj = ({ meInfo, goalInfo, as, href, goalUID }) => {
  const { isPlusAvailable, isRecordedGoal, isUaIconGoal, slug, uid } = goalInfo;
  const plusSubscriptions = getPlusSubscriptionsUids(meInfo);
  const liteSubscriptions = getLiteSubscriptionsUids(meInfo);
  const coreSubscriptions = getCoreSubscriptionUIDs(meInfo);
  const offlineSubscriptions = getActiveOfflineSubscriptionUIDs(meInfo);
  const isSubscribed = [
    ...plusSubscriptions,
    ...offlineSubscriptions,
    ...coreSubscriptions
  ].includes(goalUID);

  const isSubscribedToLite = liteSubscriptions?.includes(goalUID);
  let asRedirectionURL = as;
  let redirectionURL = href;
  if (isSubscribed && isPlusAvailable) {
    asRedirectionURL = `/goal/${slug}/${uid}/planner`;
    redirectionURL = '/goal/[goalSlug]/[goalUID]/planner';
    if (isUaIconGoal) {
      if (isSubscribed) {
        asRedirectionURL = '/icons';
        redirectionURL = '/icons';
      } else {
        asRedirectionURL = `/goal/${slug}/${uid}/icons-home`;
        redirectionURL = '/goal/[goalSlug]/[goalUID]/icons-home';
      }
    } else if (isRecordedGoal) {
      asRedirectionURL = `/goal/${slug}/${uid}/browse`;
      redirectionURL = '/goal/[goalSlug]/[goalUID]/browse';
    }
  } else if (isSubscribedToLite) {
    asRedirectionURL = `/goal/${slug}/${uid}/browse`;
    redirectionURL = '/goal/[goalSlug]/[goalUID]/browse';
  }
  return { href: redirectionURL, as: asRedirectionURL };
};

export const getRedirectionURLOnLogoClick = async ({ goalUID }) => {
  const accessToken = readCookie('accessToken');
  const promises = [];
  promises.push(fetchGoalInfo(goalUID));
  promises.push(fetchMyInfo(accessToken));
  const [goalInfo, meInfo] = await Promise.all(promises);
  return getRedirectionUrlObj({
    meInfo,
    goalInfo,
    as: '/',
    href: '/',
    goalUID
  });
};

export const getRedirectionURLAfterPostLogin = async ({
  selectedGoalUid,
  selectedGoal,
  meRawResponse,
  pathname
}) => {
  const redirectionUrlObj = getRedirectionURL(selectedGoal, pathname);
  if (!redirectionUrlObj) return redirectionUrlObj;
  const goalInfo = await fetchGoalInfo(selectedGoalUid);
  const accessToken = readCookie('accessToken');
  const accessControl = await fetchAccessControl(selectedGoalUid, accessToken);
  if (
    accessControl?.restrictToQrScanRoute &&
    pathname !== QR_ATTENDANCE_ROUTE
  ) {
    return {
      href: QR_ATTENDANCE_ROUTE,
      as: QR_ATTENDANCE_ROUTE
    };
  }
  const formattedMe = camelcaseKeys(meRawResponse, { deep: true });
  const redirectionUrl = getRedirectionUrlObj({
    meInfo: formattedMe,
    goalInfo,
    ...(redirectionUrlObj || {}),
    goalUID: selectedGoalUid
  });
  return redirectionUrl;
};

export const addBannerParamToURL = (url) => {
  const hasQueryParams = url.includes('?');

  if (hasQueryParams) {
    return `${url}&banner=true`;
  }
  return `${url}?banner=true`;
};

export const generateFeatureCards = (
  isPracticeAvailable,
  isNoTestGoal,
  isAADEnabledFlag,
  isCatalogueFeatureAvailable,
  goalSlug,
  goalUID
) => {
  const isMobile = deviceHelper.isMobileDevice();
  const featureCardsMapping = [
    {
      title: 'Live classes',
      mwebTitle: 'Live classes',
      subtitle: 'Watch free online coaching classes by our best educators.',
      color: 'var(--color-i-red-1)',
      icon: LiveClasses,
      href: '/goal/[goalSlug]/[goalUID]/classes',
      as: `/goal/${goalSlug}/${goalUID}/classes`,
      eventName: 'Live Classes Section Clicked'
    },
    {
      title: 'Top educators',
      mwebTitle: 'Educators',
      subtitle: 'Learn from some of the best educators in the country.',
      color: 'var(--color-i-blue-18)',
      icon: Educators,
      href: '/goal/[goalSlug]/[goalUID]/educators',
      as: `/goal/${goalSlug}/${goalUID}/educators`,
      eventName: 'Top Educators Section Clicked'
    },
    {
      title: 'Batches',
      mwebTitle: 'Batches',
      subtitle:
        'Curated batches to simplify the learning journey for your goal.',
      color: 'var(--color-i-yellow-14)',
      icon: Batch,
      href: '/goal/[goalSlug]/[goalUID]/batches',
      as: `/goal/${goalSlug}/${goalUID}/batches`,
      eventName: 'Batches - Batch Section Clicked'
    },
    {
      title: 'Courses',
      mwebTitle: 'Courses',
      subtitle: 'Learn every subject in detail from your favourite educator.',
      color: 'var(--color-i-blue-46)',
      icon: Course,
      href: '/goal/[goalSlug]/[goalUID]/popular_courses',
      as: `/goal/${goalSlug}/${goalUID}/popular_courses`,
      eventName: 'Courses - Course Section Clicked'
    },
    {
      title: 'Playlist',
      mwebTitle: 'Playlist',
      subtitle:
        'High quality lecture videos for the entire syllabus for all your subjects.',
      color: 'var(--color-i-turquoise-4)',
      icon: PlayList,
      href: '/goal/[goalSlug]/[goalUID]/topics',
      as: `/goal/${goalSlug}/${goalUID}/topics`,
      eventName: 'Syllabus - Section Clicked'
    }
  ];

  const filteredFeatures = [];
  // check if practice is available
  if (isPracticeAvailable) {
    filteredFeatures.push({
      title: 'Practice',
      mwebTitle: 'Practice',
      subtitle:
        'Strengthen your exam preparation with adaptive practice tests.',
      color: 'var(--color-i-violet-15)',
      icon: isMobile ? MwebPractice : Practice,
      href: '/goal/[goalSlug]/[goalUID]/practice/topic',
      as: `/goal/${goalSlug}/${goalUID}/practice/topics`,
      eventName: 'Practice - Practice Section Clicked'
    });
  }

  // Check if Test Series should be added
  if (!isNoTestGoal) {
    filteredFeatures.push({
      title: 'Test series',
      mwebTitle: 'Test series',
      subtitle: 'Evaluate and boost your exam preparation with test series.',
      color: 'var(--color-i-blue-20)',
      icon: isMobile ? MwebTest : TestSeries,
      href: '/goal/[goalSlug]/[goalUID]/test-series',
      as: `/goal/${goalSlug}/${goalUID}/test-series`,
      eventName: 'Test - Tests Section Clicked'
    });
  }

  // Check if Doubts & Solutions should be added
  if (isAADEnabledFlag || isCatalogueFeatureAvailable) {
    filteredFeatures.push({
      title: 'Doubts & solutions',
      mwebTitle: 'Doubts',
      subtitle: 'Get quick and detailed solutions to clarify your doubts.',
      color: 'var(--color-i-green)',
      icon: DoubtsAndSolutions,
      href: '/goal/[goalSlug]/[goalUID]/doubts-and-solutions',
      as: `/goal/${goalSlug}/${goalUID}/doubts-and-solutions`,
      eventName: 'Ask a Doubt - Tab Clicked'
    });
  }

  return [...featureCardsMapping, ...filteredFeatures];
};
